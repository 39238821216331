import React, { useEffect, useMemo, useState } from "react";
import styles from './styles.module.scss';
import { FormattedMessage, useIntl } from "react-intl";
import { getAllOrganizationLocations } from "actions/organizationActions";
import { useMergeEditReportState, useEditReportState, ReportFields, ReportData } from "context/editReport";
import { Select } from "components/Forms/Select";
import { LocationNotes } from '../LocationNotes';
import { FlagText } from "components/Common/FlagText";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";
import { Location } from 'components/Forms/Location';
import { useClassicKingston } from "lib/config";
interface LocationOption {
  id: number;
  location: string;
}


export const useLocationSelect = () => {
  const mergeReportData = useMergeEditReportState();
  const currentState = useEditReportState(); 
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });
  const intl = useIntl();
  const [locationOptions, setLocationOptions] = useState<LocationOption[]>([]);
  const reportData: ReportData = useEditReportState();
  const locationValue = useMemo(() => { return reportData.reportLocation; }, [reportData]);
  const getCityIfOntarioCanada = (address?: string) => {
    if (!address) {
      return undefined;
    }
  
    const parsedAddress = address.split(', ');
  
    if(parsedAddress.length === 1) {
      // Do not assume this is Ontario, Canada. E.g. it could be 'London, UK', not 'London, ON, Canada'.
      return undefined;
      // return parsedAddress[0];
    } else if (
      parsedAddress.length >= 3 && 
      parsedAddress[parsedAddress.length - 1] === 'Canada' && 
      parsedAddress[parsedAddress.length - 2] === 'ON'
    ) {
      // This only works if it's formatted in the text way for Canada addresses from google.
     return parsedAddress[parsedAddress.length - 3]; 
    } else {
      return undefined;
    }
  }



  const onLocationChange = async (location: string) => {
    mergeReportData({ supportLocation: location });
  };

  const mergeLocationReportData = (userInfoKey: string, userInfoValue: any) => {
    let newLocation = undefined;
    const canadaCity = getCityIfOntarioCanada(userInfoValue);
    if (canadaCity) {
      newLocation = locationOptions.find((lo) => {
        return lo.location === canadaCity;
      });
    }

    if(newLocation) {
      mergeReportData({supportLocation: newLocation.location});
    }
    
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await getAllOrganizationLocations();
      const locationOptions = locations.reduce<LocationOption[]>((acc, location) => {
        acc.push({
          id: acc.length,
          location: location
        } as LocationOption);
        return acc;
      }, []);
      if (locationOptions.length && useClassicKingston) {
        mergeReportData({ supportLocation: locationOptions.find(l => l.location === 'Kingston')?.location ?? '' });
      }
      setLocationOptions(locationOptions);
    }
    fetchLocations();
  }, []);

  return {
    intl,
    currentState,
    locationOptions,
    onLocationChange,
    hasPartneredOrganizationTypes,
    mergeLocationReportData,
    locationValue
  }

}


export const LocationSelect = () => {

  const {
    intl,
    currentState,
    locationOptions,
    onLocationChange,
    hasPartneredOrganizationTypes,
    mergeLocationReportData,
    locationValue
  } = useLocationSelect()

  return (
    <div className={styles.locationNotes}>
    <div className={styles.closestCity}>
      <FormattedMessage
        id='accessSupport.closestCity'
        defaultMessage="If you are in Ontario, Canada what’s the closest city/town that would be convenient for you to receive support?"
      />
    </div>
    <div className={styles.locationSelectContainer}>
      <Select
        value={currentState.supportLocation ?? ''}
        options={locationOptions}
        onChange={onLocationChange}
        valueName={'location'}
        textName={'location'}
      />
    </div>

    {currentState.supportLocation &&
      <FlagText className={styles.locationSelectFlagText}>
        {currentState.supportLocation && 
          <LocationNotes 
            location={currentState.supportLocation} 
            hasOrganizationTypes={hasPartneredOrganizationTypes}/>
        }
      </FlagText>
    }
      <FlagText className={styles.locationInputFlagText}>
        <FormattedMessage 
          id="reportStart.body.locationText" 
          defaultMessage="If the city/town that is most convenient for you is not listed and you’d like to see more resources in your area, let us know by providing your city or region."
        />
      </FlagText>

      <Location
        onChange={(location) => { mergeLocationReportData(ReportFields.ReportLocation, location) }}
        placeholder={intl.formatMessage({ id: 'home.location.placeholder' })}
        value={locationValue}
        className={styles.locationInput}
      />
  </div>
  );
}