import styles from './styles.module.scss';
import { BaseModal } from '../BaseModal';
import { WriteStoryImage } from 'components/Images';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonStyle } from 'components/Buttons';
import { useState } from 'react';
import { ReportPrintComponent, useReportPrintComponent } from 'pages/ReportPrint';
import { PasswordModal, PasswordModalActionType } from '../PasswordModal';
import { SaveModal, useSaveReport, SaveModalActionType } from '../SaveModal';
import { SaveAndDownloadJournalActionType } from 'components/Common/SaveAndDownloadJournal';
import { useEditReportState } from 'context';
import { DeleteModal } from '../DeleteModal';

interface DownloadModalProps {
  downloadModalIsOpen: boolean;
  setDownloadModalIsOpen: (value: boolean) => void;
  action?: SaveAndDownloadJournalActionType;
  alreadySubmitted?: boolean;
}

export const DownloadModal = ({ 
  downloadModalIsOpen, 
  setDownloadModalIsOpen,
  action = SaveAndDownloadJournalActionType.SaveAndDownload,
  alreadySubmitted = false,
}: DownloadModalProps) => {

  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const reportData = useEditReportState();

  const {
    saveReport,
    errorSaveSubmitDeleteModalIsOpen, 
    setErrorSaveSubmitDeleteModalIsOpen,
    attachmentFailedModalIsOpen, 
    setAttachmentFailedModalIsOpen,
    saveReportUserInfo,
    isSavedReportModalOpen, 
    setIsSavedReportOpen,
    isSubmitReportModalOpen, 
    setIsSubmitReportOpen
  } = useSaveReport({ modalAction: SaveModalActionType.Save });

  const openPasswordModal = async () => {
    const alreadySaved = !!reportData.id;
    if(alreadySaved) {
      await saveReport();
    } else {
      setPasswordModalIsOpen(true);
    }
  }

  const closeDownloadModal = () => {
    setDownloadModalIsOpen(false);
  }

  const { printRef, handlePrint } = useReportPrintComponent();

  return (
    <>
    <ReportPrintComponent ref={printRef} />
    <BaseModal
      isOpen={downloadModalIsOpen}
      onClose={closeDownloadModal}
      className={styles.downloadModal}
      icon={<WriteStoryImage className={styles.writeStoryImage} />}>
      <div className={styles.body}>
        <p>
          <FormattedMessage id="downloadModal.button.downloadLink" defaultMessage="Here if your download link for your journal."/>
        </p>
        <Button 
          className={styles.downloadLinkButton}
          style={ButtonStyle.Underline}
          onClick={() => { handlePrint(); }} //</div>() => { handlePrint(); }}
          >
            <FormattedMessage id="downloadModal.button.link" defaultMessage="Print Link" />
        </Button>
        {(action === SaveAndDownloadJournalActionType.SaveAndDownload) &&
          <p>
            <FormattedMessage 
              id="downloadModal.paragraph"
              defaultMessage="You may continue filling out your Vesta journal online to connect to local services. Your information will {highlight} be saved nor shared if you choose not to. "
              values={{ 
                highlight: <span className={styles.highlightedText}><FormattedMessage id="downloadModal.pararaph.highlight" defaultMessage="not"/></span>,
              }}/>
          </p>
        }
         {!alreadySubmitted  && <Button
          className={styles.continueButton}
          onClick={closeDownloadModal}>
          <FormattedMessage id="downloadModal.button.continue" defaultMessage="I would like to continue to connect"/>
        </Button>
        }
        {(action === SaveAndDownloadJournalActionType.SaveAndDownload) &&
          <><Button
            onClick={openPasswordModal}
            style={ButtonStyle.Underline}>
            {reportData.dateSubmitted 
            ? <FormattedMessage id="downloadModal.button.updateSubmittedReport" defaultMessage="I would like to update the submitted report"/>
            : <FormattedMessage id="downloadModal.button.saveUnsubmittedReport" defaultMessage="I would like to save and continue another time"/> 
          }
          </Button>
          </>
        }
      </div>
    </BaseModal>
    <PasswordModal
      isPasswordModalOpen={passwordModalIsOpen} 
      setIsPasswordModalOpen={setPasswordModalIsOpen}
      passwordModalAction={PasswordModalActionType.Save}
    />
    <SaveModal
      errorSaveSubmitDeleteModalIsOpen={errorSaveSubmitDeleteModalIsOpen} 
      setErrorSaveSubmitDeleteModalIsOpen={setErrorSaveSubmitDeleteModalIsOpen} 
      attachmentFailedModalIsOpen={attachmentFailedModalIsOpen} 
      setAttachmentFailedModalIsOpen={setAttachmentFailedModalIsOpen} 
      saveReportUserInfo={saveReportUserInfo} 
      isSavedReportModalOpen={isSavedReportModalOpen} 
      setIsSavedReportOpen={setIsSavedReportOpen} 
      isSubmitReportModalOpen={isSubmitReportModalOpen} 
      setIsSubmitReportOpen={setIsSubmitReportOpen} 
      saveModalAction={SaveModalActionType.Save}
    />
    </>
  );
}