import React, { ReactNode, useState } from "react";
import { FormattedMessage } from 'react-intl';
import classNames from "classnames";
import styles from './styles.module.scss';
import { Button, ButtonStyle, LinkButton } from "components/Buttons";
import { ReportAnonymouslyModal } from "components/Modals/ReportAnonymouslyModal";
import { ContactLawEnfOrSuppCenModal } from "components/Modals/ContactLawEnfOrSuppCenModal";
import { SeekSupportModal } from "components/Modals/SeekSupportModal";
import { useEditReportState, useMergeEditReportState } from "context/editReport";
import { PasswordModal, PasswordModalActionType } from "components/Modals/PasswordModal";
import { PhoenixImage, ShoulderSupportImage, TalkPoliceOrSupportImage, WriteStoryImage } from 'components/Images';
import { Router, useHistory } from "react-router-dom";
import { useEditUserState, UserInfo } from "context/editUser";
import Footer from "Layout/Footer";
import { RoutePaths } from "App/routing";
import { ArrowRightDark } from "components/Icons";
import { Checkbox } from "components/Forms/Toggle/Checkbox";
import { CampusSupportModal } from "components/Modals/CampusSupportModal";
import { SaveAndDownloadJournal } from "components/Common/SaveAndDownloadJournal";
import useHasOrganizationTypes from "lib/useHasOrganizationsTypes";
import { hasPartneredOrganization } from "lib/commonFunctions";
import { LocationSelectionModal } from "components/Modals/LocationSelectionModal";

export enum ReportPathType {
  ReportAnonymously = "reportAnonymously",
  ReportCampusAnonymously = "reportCampusAnonymously",
  ContactLawEnfOrSuppCen  = "contactLawEnfOrSuppCen",
  modifiedAccessSupport = "modifiedAccessSupport",
  saveYourRecord = "saveYourRecord"
}
 type ReportPath = {
  id: number
  image?: ReactNode
  textTag: string
  helpText: string
  defaultMsg: string
  infoModal: boolean
  linkPath: string
  reportPathType?: ReportPathType|string
  checkbox?: boolean;
  checkboxText?: string;
  showReportOptions?: boolean;
}

export const ReportPath = () => {


  const reportAnonReportPath =   {
    id: 1, textTag: 'report.reportAnonymously', helpText: 'report.reportAnonymously.Message', 
    image:  <PhoenixImage  className={styles.image}/>,  defaultMsg: 'Report Anonymously', infoModal: true, 
    linkPath: RoutePaths.personalInfo, reportPathType: 'reportAnonymously', checkbox: false, checkboxText: "", 
    showReportOptions: false
  };
  
  const pathNames = [

    {
      id: 2,
      textTag: 'report.saveYourRecord',
      helpText: 'report.saveYourRecord.Message', 
      image:  <WriteStoryImage  className={styles.image}/>,
      defaultMsg: 'Save your record', infoModal: false, 
      linkPath: RoutePaths.reportStart, reportPathType: ReportPathType.saveYourRecord, 
      checkbox: false, checkboxText: "", 
      showReportOptions: false
    },
    {
      id: 0, 
      textTag: 'report.downloadYourRecord', 
      helpText: 'report.downloadYourRecord.Message', 
      image:  <TalkPoliceOrSupportImage  className={styles.image}/>,  
      defaultMsg: 'Connect with or report to', infoModal: false, 
      linkPath: RoutePaths.personalInfo, reportPathType: ReportPathType.ReportAnonymously,
      // 'contactLawEnfOrSuppCen', 
      checkbox: false, checkboxText: "", showReportOptions: false
    }
  ]
  

  const userInfo: UserInfo = useEditUserState();
  const mergeReportData = useMergeEditReportState();
  const reportData = useEditReportState();
  const hasPartneredOrganizationTypes = useHasOrganizationTypes({ isPartnered: true });

  const [reportAnonymouslyModalIsOpen, setReportAnonymouslyModalIsOpen] = useState(false);
  const [contactLawEnfOrSuppCenModalIsOpen, setContactLawEnfOrSuppCenModalIsOpen] = useState(false);
  const [SeekSupportModalIsOpen, setSeekSupportModalIsOpen] = useState(false);
  const [SeekCampusSupportModalIsOpen, setSeekCampusSupportModalIsOpen] = useState(false);

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [selectedReportPath, setSelectedReportPath] = useState<ReportPath>();
  const [campusSupport, setCampusSupport] = useState(false);
  const [openLocationSelect, setOpenLocationSelect] = useState(false);

  const history = useHistory();

  const openReportAnonymouslyModal = () => {
    setReportAnonymouslyModalIsOpen(true);
  }

  const openContactLawEnfOrSuppCenModal = () => {
    setContactLawEnfOrSuppCenModalIsOpen(true);
  }

  const openSeekSupportModal = () => {
    setSeekSupportModalIsOpen(true);
  }

  const onReportPathClick = (reportPath: ReportPath) => {
    if(reportPath.id === 2 && campusSupport) {
      mergeReportData({reportPathType:"reportCampusAnonymously", allowSupportCenterContact:true});  
    } else {
      mergeReportData({reportPathType:reportPath.reportPathType});  
    }
    history.push(reportPath.linkPath);
  }

  const onSaveAndExitClick = () => {
    setIsPasswordModalOpen(true);
  }

  const onPrintOnlyClick = () => {
    history.push(RoutePaths.reportPrintFinal);
    // setIsPasswordModalOpen(true);
  }

  const onInfoClick = (pathId: number) =>{
    if(pathId === 0){
      openContactLawEnfOrSuppCenModal();
    }
    else if(pathId === 1){
      openReportAnonymouslyModal();
    }
    else if(pathId === 2){
      openSeekSupportModal();
    }
  }

  const onPathClick = (path: ReportPath) =>{
    setSelectedReportPath(path);
  }
  
  const selectCampusPath = () => {
    if(!campusSupport){
      setSeekCampusSupportModalIsOpen(true);
    }
    setCampusSupport(!campusSupport);
  }

  const isDisabledPathContactLawEnfOrSuppCen = () => {
    return (
      selectedReportPath?.reportPathType === ReportPathType.ContactLawEnfOrSuppCen &&
      !hasPartneredOrganization(hasPartneredOrganizationTypes)
    );
  }

  return (    
    <div className={styles.reportPath}>
      <h3 className={styles.title}><FormattedMessage id="report.howShareYourStory" defaultMessage="How would you like to tell your story?" /></h3>
      <div className={styles.reportPathContent}>
        <div className={styles.left}>
          {(pathNames).map((path) => { 
            return (
            <div className={styles.pathCard}>
              <Button 
                onClick={() => onPathClick(path)}
                style={ButtonStyle.PathOption}
                className={
                  classNames(path.id === selectedReportPath?.id && styles.active, 
                            styles.pathCardButton)}>
                <h3 className={styles.buttonTitle}>
                  <FormattedMessage 
                  id={path.textTag} 
                  defaultMessage={path.defaultMsg}/>   
                </h3>
                {(path.checkbox) && (
                  <div className={styles.checkboxContainer}
                  onClick={selectCampusPath}>
                    <Checkbox 
                      value={path.checkboxText}
                      name={path.checkboxText}
                      checked={campusSupport}
                      id={path.checkboxText}
                      onChange={() => {}}
                      />  
                      <div className={styles.checkbox}>
                        {path.checkboxText}
                      </div>
                  </div> 
                )}            
                {( path.showReportOptions) && (
                  <div className={styles.innerButtonText}>
                     <ul>
                      <li>Campus</li>
                      <li>Support Center</li>
                      <li>Law Enforcement</li>
                    </ul>
                  </div> 
                )} 
                <ArrowRightDark className={styles.arrow}/>
              </Button>   
             
              <div className={classNames(styles.mobileOnly, path.id === selectedReportPath?.id && styles.active)}>
                {selectedReportPath && <p className={styles.helpText}>
                  {isDisabledPathContactLawEnfOrSuppCen() 
                    ? <FormattedMessage 
                        id={"report.contactLawEnfOrSuppCen.Message.Disabled"}
                        values={{
                          bold1: (
                            <span className={classNames(styles.attention, styles.vestaPink)}>
                              <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.bold1`}/>
                            </span>
                          ),
                          bold2: (
                            <span className={classNames(styles.attention, styles.vestaPink, styles.clickable)}  onClick={() => onPathClick(reportAnonReportPath)}>
                              <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.bold2`}/>
                            </span>
                          ),
                          linebreak: (
                            <>
                              <br/>
                              <br/>
                            </>
                          ),
                          location: reportData.supportLocation || (
                            <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault`}/>
                          ),
                        }}
                      />
                    : <FormattedMessage 
                        id={selectedReportPath?.helpText}
                        values={{ 
                          bold: (
                            <span className={classNames(styles.attention, styles.vestaPink)}>
                              <FormattedMessage id={`${selectedReportPath.helpText}.bold`}/>
                            </span>
                          ),
                        }}
                      /> 
                  }
                </p>}
                {selectedReportPath?.infoModal && <Button onClick={() =>onInfoClick(selectedReportPath.id)}  
                  style={ButtonStyle.Underline}
                  className={styles.stepAwayButton}>
                  <FormattedMessage id="report.meanForYou" defaultMessage="What does this mean for you?"/>  
                </Button>}
                {selectedReportPath?.infoModal && <SaveAndDownloadJournal />}
                {selectedReportPath?.infoModal && <Button onClick={() =>onReportPathClick(selectedReportPath)}
                      className={styles.nextButton}
                      style={ButtonStyle.Secondary}
                      disabled={isDisabledPathContactLawEnfOrSuppCen()}>
                        <FormattedMessage id="report.reviewSupport" defaultMessage="Next"/>             
                    </Button>}
                {!selectedReportPath?.infoModal && <Button onClick={onSaveAndExitClick}
                      className={styles.saveExitButton}
                      style={ButtonStyle.Secondary}>
                        <FormattedMessage id="report.saveAndPrint" defaultMessage="Save and Complete (default)"/>
                    </Button>}
                </div>    
              </div>
          )})}
        </div> 

        <div className={styles.bar}>
        </div>
        <div className={styles.right}>
          {selectedReportPath && (
            <>
              {selectedReportPath.image}
              <p className={styles.helpText}>
                {(
                        !reportData.dateSubmitted ||
                        (!reportData.allowCampusContact && 
                        !reportData.allowPoliceContact && 
                        !reportData.allowSupportCenterContact)) ? (
                          !!reportData.supportLocation ?
                        <FormattedMessage
                          id={'report.contactLawEnfOrSuppCen.selectLocation'}
                          values={{
                            location: reportData.supportLocation || (
                              <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault`}/>
                            ),
                            link: (
                              <Button onClick={() => setOpenLocationSelect(true)}  
                                style={ButtonStyle.Underline}
                                className={classNames(styles.attention, styles.vestaPink)}>
                                <FormattedMessage id="report.contactLawEnfOrSuppCen.selectLocation.link"/>  
                              </Button>
                            ),
                            linebreak: (<><br/><br/></>),
                          }}
                          /> : 
                          <FormattedMessage
                          id={'report.contactLawEnfOrSuppCen.noLocation'}
                          values={{
                            location: reportData.supportLocation || (
                              <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault`}/>
                            ),
                            link: (
                              <Button onClick={() => setOpenLocationSelect(true)}  
                                style={ButtonStyle.Underline}
                                className={classNames(styles.attention, styles.vestaPink)}>
                                <FormattedMessage id="report.contactLawEnfOrSuppCen.selectLocation.link"/>  
                              </Button>
                            ),
                            linebreak: (<><br/><br/></>),
                          }}
                          />
                        ) : (
                          <FormattedMessage id={'report.contactLawEnfOrSuppCen.reportSent'} values={{linebreak: (<><br/><br/> </>), supportLocation: reportData.supportLocation }}/>
                        )
                } 
                {isDisabledPathContactLawEnfOrSuppCen()
                  ? <>
                      <FormattedMessage 
                          id={"report.contactLawEnfOrSuppCen.Message.Disabled"}
                          values={{
                            bold1: (
                              <span className={classNames(styles.attention, styles.vestaPink)}>
                                <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.bold1`}/>
                              </span>
                            ),
                            bold2: (
                              <span className={classNames(styles.attention, styles.vestaPink)} onClick={() => onPathClick(reportAnonReportPath)}>
                                <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.bold2`}/>
                              </span>
                            ),
                            linebreak: (<><br/><br/></>
                            ),
                            location: reportData.supportLocation || (
                              <FormattedMessage id={`report.contactLawEnfOrSuppCen.Message.Disabled.locationDefault`}/>
                            ),
                          }}
                        />
                      </>
                    : <FormattedMessage 
                        id={selectedReportPath?.helpText}
                        values={{
                          bold: (
                            <span className={classNames(styles.attention, styles.vestaPink)}>
                              <FormattedMessage  id={`${selectedReportPath.helpText}.bold`}/>
                            </span>
                          ),
                        }}
                    />   
                }         
              </p>         
              {selectedReportPath.infoModal && (
                <Button onClick={() =>onInfoClick(selectedReportPath.id)}  
                  style={ButtonStyle.Underline}
                  className={styles.stepAwayButton}>
                  <FormattedMessage id="report.meanForYou" defaultMessage="What does this mean for you?"/>  
                </Button>
              )}
              {selectedReportPath.infoModal && 
                <SaveAndDownloadJournal />
              }
              {selectedReportPath.infoModal && (
                <Button onClick={() =>onReportPathClick(selectedReportPath)}
                  className={styles.nextButton}
                  style={ButtonStyle.Secondary}
                  disabled={isDisabledPathContactLawEnfOrSuppCen()}>
                    <FormattedMessage id="report.reviewSupport" defaultMessage="Next"/>             
                </Button>
              )}
              {!selectedReportPath.infoModal && selectedReportPath.reportPathType === ReportPathType.saveYourRecord && (
                <Button onClick={onSaveAndExitClick}
                  className={styles.saveExitButton}
                  style={ButtonStyle.Secondary}>
                    <FormattedMessage id="report.saveAndPrint" defaultMessage="Save and ext"/>
                </Button>
              )}
              {!selectedReportPath.infoModal && selectedReportPath.reportPathType === ReportPathType.ReportAnonymously && (
                <Button onClick={onPrintOnlyClick}
                  className={styles.saveExitButton}
                  style={ButtonStyle.Secondary}>
                    <FormattedMessage id="report.PrintOnly" defaultMessage="Print"/>
                </Button>
              )}
            </>
          )} 
          </div>          
      </div>     

      <Footer isReportForm={false} isBreak={false}/>

      <PasswordModal  setIsPasswordModalOpen={setIsPasswordModalOpen}
        isPasswordModalOpen={isPasswordModalOpen}
        passwordModalAction={userInfo.reportKey ? PasswordModalActionType.Resave : PasswordModalActionType.Save} 
        />
      <ReportAnonymouslyModal 
        reportAnonymouslyModalIsOpen={reportAnonymouslyModalIsOpen}
        setReportAnonymouslyModalIsOpen={setReportAnonymouslyModalIsOpen}/>
      <ContactLawEnfOrSuppCenModal contactLawEnfOrSuppCenModalIsOpen={contactLawEnfOrSuppCenModalIsOpen}
        setContactLawEnfOrSuppCenModalIsOpen={setContactLawEnfOrSuppCenModalIsOpen}/>
      <SeekSupportModal SeekSupportModalIsOpen={SeekSupportModalIsOpen}
        setSeekSupportModalIsOpen={setSeekSupportModalIsOpen}/>
      <CampusSupportModal campusSupportModalIsOpen={SeekCampusSupportModalIsOpen}
        setCampusSupportModalIsOpen={setSeekCampusSupportModalIsOpen}/>
      <LocationSelectionModal onClose={() => setOpenLocationSelect(false)} isOpen={openLocationSelect}/>
    </div>
  );
}
