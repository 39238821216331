import { useState } from "react";
import styles from './styles.module.scss';
import { DownloadModal } from "components/Modals/DownloadModal";
import { Button, ButtonStyle } from "components/Buttons";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

export interface SaveAndDownloadJournalProps {
  className?: string;
  action?: SaveAndDownloadJournalActionType;
  alreadySubmitted?: boolean;
  buttonStyle?: ButtonStyle;
}

export enum SaveAndDownloadJournalActionType {
  SaveAndDownload = "SaveAndDownload",
  DownloadOnly = "DownloadOnly",
}

export const SaveAndDownloadJournal = ({
  className,
  action = SaveAndDownloadJournalActionType.SaveAndDownload,
  alreadySubmitted = false,
  buttonStyle = ButtonStyle.DarkPrimaryUnderline
}: SaveAndDownloadJournalProps) => {
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  
  return (
   <>
    <div className={classNames(styles.downloadButtonContainer, className)}>
      <Button 
        onClick={() => setDownloadModalIsOpen(true)}
        style={buttonStyle} 
        className={styles.downloadButton}>
        {(action === SaveAndDownloadJournalActionType.SaveAndDownload)
          ? <FormattedMessage id="report.saveAndDownload" defaultMessage="Save and download / print my journal"/>
          : <FormattedMessage id="report.downloadOnly" defaultMessage="Download / print my journal"/>
        }
      </Button>
    </div>
    <DownloadModal 
      downloadModalIsOpen={downloadModalIsOpen} 
      setDownloadModalIsOpen={setDownloadModalIsOpen}
      action={action}
      alreadySubmitted={alreadySubmitted}
    />
   </> 
  );
}