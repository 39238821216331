import { useEditUserState, UserInfo } from "context/editUser";
import { ReportData, useEditReportState } from "context/editReport";
import styles from "./styles.module.scss";
import Header from "Layout/Header";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { SaveReportUserInfoType } from "components/Modals/PasswordModal";
import { PhoenixImage } from "components/Images";
import { useLogoutUser } from "actions/userActions";
import { Button, ButtonStyle } from "components/Buttons";
import { updateReportTracking } from "actions/reportActions";
import { vestaExternalLinks } from "lib/config";
import { ExternalLinkIcon, FlagIconPink } from "components/Icons";
import { SaveAndDownloadJournal, SaveAndDownloadJournalActionType } from "components/Common/SaveAndDownloadJournal";
import useOrganizations from "lib/useOrganizations";

export const ReportSubmitSuccess = ({}) => {
  const reportData: ReportData = useEditReportState();
  const userInfo: UserInfo = useEditUserState();
  const [saveReportUserInfo, setSavedReportUserInfo] =
    useState<SaveReportUserInfoType>({ reportKey: "", fullName: "" });

  const { supportOrganization, campusSupportOrganization } = useOrganizations();

  const actualSupportOrganization = reportData.supportOrganization || supportOrganization;
  const actualCampusSupportOrganization = reportData.campusSupportOrganization || campusSupportOrganization;

  const logoutUser = useLogoutUser();

  useEffect(() => {
    if (userInfo?.reportKey) {
      setSavedReportUserInfo({
        reportKey: userInfo.reportKey,
        fullName: userInfo.fullName,
      });
    }
  }, [userInfo]);

  const onClickIntakeForm = async () => {
    if( userInfo && userInfo.reportId && reportData.supportOrganization) {
      await updateReportTracking(userInfo.reportId, reportData.supportOrganization.id);
    }
  };

  const viewResourcesAndLogOut = async () => {
    await logoutUser();
    window.open(vestaExternalLinks.resources);
  };

  return (
    <div className={styles.reportSubmitSuccessPageContainer}>
      <div className={styles.header}>
        <Header showBackButton={false} />
      </div>
      <div className={styles.reportSubmitSuccessContent}>
        <div className={styles.left}>
          <PhoenixImage className={styles.phoenixImage} />
          <h5>
            <FormattedMessage
              id="submitReport.title"
              defaultMessage="Your report has been successfully submitted"
            />
          </h5>
          <div className={styles.reportKeyHeader}>
            <h1 className={classNames(styles.attention, styles.vestaPink)}>
              {saveReportUserInfo.reportKey}
            </h1>
            <p className={styles.preline}>
              <FormattedMessage
                id="submitReportModal.reportKeyHeader"
                defaultMessage="You can use this key and your selected password to access your report."
              />
            </p>
          </div>

          <p className={styles.body}>
            <FormattedMessage
              id="submitReport.body"
              values={{
                youDidGreat: (
                  <span
                    className={classNames(styles.attention, styles.vestaPink)}
                  >
                    <FormattedMessage
                      id="submitReport.body.youDidGreat"
                      defaultMessage="You did great!"
                    />
                  </span>
                ),
              }}
              defaultMessage="You did great! You’ve taken an amazing step towards healing. Now, we’ll bring you to explore more resources to help you through your journey. Take a deep breath and have a cold glass of water. You are in control."
            />
          </p>
          <p className={styles.body}>
            <FormattedMessage id="submitReport.resourceReminder" 
              defaultMessage="!!Once you view Vesta resources, your session will end. You will need to access your journal using your key and password if you need to download your report later."
            />
            <FormattedMessage id="submitReport.resourceReminder.police" 
              defaultMessage="!!If you decide to report to the police, consider bringing yo..."
            />
          </p>
          {userInfo?.reportKey && <SaveAndDownloadJournal 
            className={styles.saveAndDownloadJournalButton}
            action={SaveAndDownloadJournalActionType.DownloadOnly}
            alreadySubmitted={true}
            buttonStyle={ButtonStyle.Primary}
          />}
          <Button
            onClick={() => {
              viewResourcesAndLogOut();
            }}
            className={styles.viewResourcesCTA}
            style={ButtonStyle.DarkPrimaryUnderline}
          >
            <FormattedMessage
              id="button.viewVestaResources"
              defaultMessage="View Vesta resources"
            />
          </Button>
        </div>
        <div className={styles.right}>
          {actualSupportOrganization && actualSupportOrganization?.organizationLocation && (
            <p>
              <FormattedMessage
                id="reportSuccess.youSelectedLocation"
                values={{
                  organizationLocation: (
                    <span
                      className={classNames(styles.attention, styles.vestaPink)}
                    >
                      {actualSupportOrganization?.organizationLocation}
                    </span>
                  ),
                }}
              />
            </p>
          )}
          {actualCampusSupportOrganization && actualCampusSupportOrganization.organizationName && (
            <p className={styles.subtitle}>
              <FormattedMessage
                id="reportSuccess.orgBlurb"
                values={{
                  organizationName:
                    actualCampusSupportOrganization?.organizationName,
                }}
              />
            </p>
          )}
          {actualSupportOrganization && 
            (actualSupportOrganization.intakeFormLink || actualSupportOrganization.crisisLink || actualSupportOrganization.crisisPhone)
            && (
            <div className={styles.submitSummary}>
               {actualSupportOrganization && actualSupportOrganization?.intakeFormLink && (
               <p className={styles.summaryPoint}>
                <FlagIconPink className={styles.iconSize} />
                <span
                  className={classNames(styles.attention, styles.vestaPink)}
                >
                  <FormattedMessage
                    id="reportSuccess.providesIntake"
                    values={{
                      organizationName:
                        actualSupportOrganization?.organizationName,
                      fillOutIntake: (
                        <span>
                          <FormattedMessage
                            id="reportSuccess.fillOutIntake"
                            values={{
                              organizationName:
                                actualSupportOrganization?.organizationName,
                            }}
                          />
                        </span>
                      ),
                    }}
                  />
                </span>
              </p> )}
              <div className={styles.links}>
                {actualSupportOrganization && actualSupportOrganization?.intakeFormLink && (
                  <p className={styles.attention}>
                    <FormattedMessage
                      id="reportSuccess.intakeForm"
                      defaultMessage="Intake form:"
                    />{" "}
                    <span>
                      <a onClick={onClickIntakeForm} href={actualSupportOrganization?.intakeFormLink} target="_blank" rel="noopener noreferrer">
                        {actualSupportOrganization?.intakeFormLink}
                        <ExternalLinkIcon className={styles.externalLinkIcon} />
                      </a>
                    </span>
                  </p>
                )}
              </div>

              {actualSupportOrganization &&
                (actualSupportOrganization.crisisLink ||
                  actualSupportOrganization.crisisPhone) && (
                  <div>
                    <p
                      className={classNames(
                        styles.summaryPoint,
                        styles.attention,
                        styles.vestaPink
                      )}
                    >
                      <FlagIconPink className={styles.iconSize} />
                      <FormattedMessage
                        id="reportSuccess.providesOther"
                        values={{
                          organizationName:
                          actualSupportOrganization?.organizationName,
                        }}
                      />
                    </p>
                    <div className={styles.links}>
                      {actualSupportOrganization && (
                        <p className={styles.attention}>
                          <FormattedMessage
                            id="reportSuccess.crisisLine"
                            defaultMessage="Crisis line:"
                          />{" "}
                          <span>
                            {actualSupportOrganization?.crisisLink}
                            <ExternalLinkIcon
                              className={styles.externalLinkIcon}
                            />
                          </span>
                        </p>
                      )}
                      {actualSupportOrganization && (
                        <p className={styles.attention}>
                          <FormattedMessage
                            id="reportSuccess.phoneNumber"
                            defaultMessage="Phone number:"
                          />{" "}
                          <span>
                            {actualSupportOrganization?.crisisPhone}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )}

              {false && actualCampusSupportOrganization &&
                !actualCampusSupportOrganization?.intakeFormLink && (
                  <p className={styles.summaryPoint}>
                    <FlagIconPink className={styles.iconSize} />
                    <span>
                      <FormattedMessage
                        id="reportSuccess.youSelectedCampus"
                        values={{
                          campusName: (
                            <span
                              className={classNames(
                                styles.attention,
                                styles.vestaPink
                              )}
                            >
                              {
                                actualCampusSupportOrganization
                                  ?.organizationName
                              }
                            </span>
                          ),
                        }}
                      />
                    </span>
                  </p>
                )}
              {false && reportData.allowPoliceContact && (
                <p className={classNames(styles.summaryPoint)}>
                  <FlagIconPink className={styles.iconSize} />
                  <span>
                    <FormattedMessage
                      id="reportSuccess.policeContact"
                      defaultMessage="You will be contacted by police directly through your preferred method of contact."
                      values={{
                        police: (
                          <span
                            className={classNames(
                              styles.attention,
                              styles.vestaPink
                            )}
                          >
                            <FormattedMessage
                              id="reportSuccess.police"
                              defaultMessage="police"
                            />
                          </span>
                        ),
                      }}
                    />
                  </span>
                </p>
              )}
            </div>
          )}
          {actualSupportOrganization &&
            (reportData.allowPoliceContact || actualSupportOrganization?.intakeFormLink || actualSupportOrganization?.crisisLink || actualSupportOrganization?.crisisPhone) && (
            <p className={classNames(styles.preline, styles.textCenter)}>
              <FormattedMessage
                id="reportSuccess.doesNotHaveInfo"
                defaultMessage="Vesta does not have access to any information provided through these links."
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
