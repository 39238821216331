import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { PrivacyAndOutcomesModal } from "components/Modals/PrivacyAndOutcomesModal";
import Header from "Layout/Header";
import styles from './styles.module.scss';
import { WriteStoryImage } from "components/Images";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, ButtonStyle, LinkButton } from "components/Buttons/Button";
import { RoutePaths } from "App/routing";
import { ArrowDownWhite } from "components/Icons";
import Footer from "Layout/Footer";
import { ReportData, ReportFields, useEditReportState, useMergeEditReportState } from "context/editReport";
import { LocationSelect } from "./LocationSelect";
import { Location } from 'components/Forms/Location';
import { FlagText } from "components/Common/FlagText";




export const ReportStart = () => {
  const [privacyAndOutcomesModalIsOpen, setPrivacyAndOutcomesModalIsOpen] = useState(false);


  const openPrivacyAndOutcomesModal = () => {
    setPrivacyAndOutcomesModalIsOpen(true);
  }


  return (
      <div className={classNames(styles.reportPage, styles.reportPageAnim)}>
        <div className={styles.reportContainer}>
          <Header showBackButton={true} showBackArrow={true} isReturnHomeType={true}/>

          <div className={styles.reportContent}>
            <div className={styles.left}>
              <p><FormattedMessage id="reportStart.body.first" defaultMessage="Take a deep breath, and think about the incident."/></p>

              <p><FormattedMessage id="reportStart.body.second" defaultMessage="How you felt, what you saw, and what you heard."/></p>

              <p><FormattedMessage id="reportStart.body.third" defaultMessage="Write down as much as possible of what you can remember about your experience. Include minor details. Take as much time as you need."/></p>

              <p><FormattedMessage id="reportStart.body.fourth" defaultMessage="You choose who can see your story."/></p>

              <p><FormattedMessage id="reportStart.body.fifth" defaultMessage="There are no wrong answers. Spelling and grammar are not important."/></p>

              <p className={classNames(styles.youAreInControl, styles.attention)}>
                <FormattedMessage id="youAreInControl" defaultMessage="You are in control"/>
            </p>
            <div className={styles.illustration}>
              <WriteStoryImage />
              </div>
            </div>

            <div className={classNames(styles.right, styles.reportNotStarted)}>
              <div className={styles.closestSupportQuestionContainer}>
                
                <LocationSelect />

                <div className={styles.reportStartButtons}>
                    <LinkButton to={RoutePaths.report}
                      className={styles.startButton}
                      style={ButtonStyle.Primary}>
                        <FormattedMessage id="button.start" defaultMessage="Let's start"/>
                        <ArrowDownWhite className={styles.buttonArrow} />
                    </LinkButton>

                    <Button onClick={openPrivacyAndOutcomesModal} 
                      style={ButtonStyle.Underline}>
                        <FormattedMessage id="reportStart.privacyAndOutcomes" defaultMessage="I’d like to learn more"/>
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer isReportForm={true} isBreak={false}/>
        <PrivacyAndOutcomesModal privacyAndOutcomesModalIsOpen={privacyAndOutcomesModalIsOpen} 
        setPrivacyAndOutcomesModalIsOpen={setPrivacyAndOutcomesModalIsOpen} />
      </div>
  );
}
